import React from "react";
import Layout from "../components/Layout";
import styled from "styled-components";

const Container = styled.div`
  text-align: center;
  padding: 128px 0;
`;

const NotFoundPage = () => (
  <Layout>
    <Container className={`container`}>
      <div className="post-content">
        <h1>404 NOT FOUND</h1>
        <p>The page you were looking for was not found</p>
      </div>
    </Container>
  </Layout>
);

export default NotFoundPage;
